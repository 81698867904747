<template>
  <section class="blank">
    <div id="blank" class="blank__blobs">
      <div v-if="!$root.isMobile" id="blob-green" class="blank__blob blank__blob--green">
        <svg viewBox="0 0 545 614" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_f_1_1747)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M280.677 144.139C328.679 141.377 370.248 180.174 393.205 222.374C412.266 257.412 391.153 296.391 384.932 335.781C377.636 381.983 397.095 445.686 354.775 465.706C312.454 485.727 275.305 430.557 235.028 406.696C202.119 387.199 157.84 379.131 146.848 342.523C135.346 304.214 160.938 267.62 183.312 234.453C209.352 195.853 234.156 146.816 280.677 144.139Z"
              fill="#00FFC5"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_1_1747"
              x="0"
              y="0"
              width="545"
              height="614"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="55" result="effect1_foregroundBlur_1_1747" />
            </filter>
          </defs>
        </svg>
      </div>
      <div v-else id="blob-green" class="blank__blob blank__blob--green">
        <svg
          width="246"
          height="268"
          viewBox="0 0 246 268"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_9_84)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M124.305 73.0521C143.347 72.0182 159.836 86.5376 168.943 102.33C176.504 115.442 168.129 130.03 165.662 144.771C162.767 162.061 170.486 185.901 153.699 193.393C136.911 200.886 122.174 180.239 106.197 171.309C93.1432 164.013 75.5783 160.994 71.2182 147.294C66.6555 132.957 76.8074 119.263 85.6829 106.851C96.0122 92.4052 105.852 74.054 124.305 73.0521Z"
              fill="#00FFC5"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_9_84"
              x="-2.91162"
              y="0"
              width="247.947"
              height="268"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="28.5" result="effect1_foregroundBlur_9_84" />
            </filter>
          </defs>
        </svg>
      </div>
      <div v-if="!$root.isMobile" id="blob-violet" class="blank__blob blank__blob--violet">
        <svg
          width="459"
          height="422"
          viewBox="0 0 459 422"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M244.477 96C274.004 96.002 300.955 110.099 322.954 126.948C343.356 142.575 359.408 163.247 362.649 186.51C365.603 207.711 349.219 226.109 339.509 245.778C330.809 263.399 324.986 281.951 308.948 295.279C291.032 310.168 269.239 321.955 244.477 324.753C217.479 327.803 187.587 325.948 166.324 311.39C145.377 297.049 145.931 270.755 134.994 249.795C122.206 225.288 90.2739 204.568 96.8929 178.334C103.494 152.17 139.784 141.558 165.991 126.938C190.725 113.139 214.949 95.998 244.477 96Z"
              fill="#530EDD"
            />
          </g>
          <defs>
            <filter
              id="filter0_f"
              x="0"
              y="0"
              width="459"
              height="422"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="48" result="effect1_foregroundBlur" />
            </filter>
          </defs>
        </svg>
      </div>
      <div v-else id="blob-violet" class="blank__blob blank__blob--violet">
        <svg
          width="251"
          height="229"
          viewBox="0 0 251 229"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_9_85)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M124.343 70.3085C135.191 70.879 146.027 66.6411 156.277 70.0216C167.962 73.8752 181.515 79.4404 185.445 90.4592C189.402 101.553 177.029 111.923 175.358 123.516C173.665 135.26 186.196 151.811 175.659 158.348C163.617 165.817 148.818 147.679 134.441 148.896C124.401 149.747 119.551 164.999 109.533 163.948C99.921 162.939 97.2676 150.607 90.3461 144.27C82.5709 137.15 69.9458 134.084 66.3977 124.451C62.7592 114.572 67.9106 103.927 71.8924 94.1658C76.0975 83.8569 79.4334 71.353 90.0209 66.5372C100.6 61.7254 112.604 69.6912 124.343 70.3085Z"
              fill="#454ADE"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_9_85"
              x="0.15918"
              y="0"
              width="251.062"
              height="229"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="32.5" result="effect1_foregroundBlur_9_85" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
    <div class="container">
      <div class="blank__texts">
        <h2>Developing Blockchain Solutions</h2>
        <p>
          We are a team of full-stack programmers and mathematicians focused on the development of
          blockchain software solutions.
        </p>

        <button class="button button--contact" tabindex="3" @click.prevent="$root.goTo('#contact')">
          Contact us
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Blank',
  components: {},
  props: {},
  data() {
    return {
      greenBall: '',
      violetBall: '',
      container: '',
      greenBallInfo: '',
      violetBalinfo: ''
    }
  },
  computed: {},
  mounted() {
    this.container = document.getElementById('blank')
    this.greenBall = document.getElementById('blob-green')
    this.violetBall = document.getElementById('blob-violet')

    this.greenBallInfo = {
      x: !this.$root.isMobile ? 800 : 200,
      y: 200,
      vx: 2,
      vy: 2,
      w: this.greenBall.getBoundingClientRect().width,
      h: this.greenBall.getBoundingClientRect().height
    }

    this.violetBallInfo = {
      x: 0,
      y: !this.$root.isMobile ? 0 : 200,
      vx: 2,
      vy: 2,
      w: this.violetBall.getBoundingClientRect().width,
      h: this.violetBall.getBoundingClientRect().height
    }

    this.containerInfo = {
      w: this.$root.width,
      h: this.container.getBoundingClientRect().height
    }

    this.render()
  },
  methods: {
    updatePositionInformation(info) {
      info.x += info.vx
      info.y -= info.vy
    },

    updatePositionnInformation(info) {
      info.x -= info.vx
      info.y += info.vy
    },

    translateDomElement(el, info) {
      el.style.transform = `translate(${info.x}px, ${info.y}px)`
    },

    translateDommElement(el, info) {
      el.style.transform = `translate(${info.x}px, ${info.y}px)`
    },

    checkXPosition(elInfo, width) {
      return elInfo.x < 0 || elInfo.x > width - elInfo.w
    },

    checkYPosition(elInfo, containerInfo) {
      return elInfo.y < 0 || elInfo.y > containerInfo.h - elInfo.h
    },

    boundaries(elInfo, xOut, yOut) {
      if (xOut) elInfo.vx *= -1
      if (yOut) elInfo.vy *= -1
    },

    render() {
      this.updatePositionInformation(this.greenBallInfo)
      this.updatePositionnInformation(this.violetBallInfo)
      this.translateDomElement(this.greenBall, this.greenBallInfo)
      this.translateDommElement(this.violetBall, this.violetBallInfo)
      this.boundaries(
        this.greenBallInfo,
        this.checkXPosition(this.greenBallInfo, this.$root.width),
        this.checkYPosition(this.greenBallInfo, this.containerInfo)
      )
      this.boundaries(
        this.violetBallInfo,
        this.checkXPosition(this.violetBallInfo, this.$root.width),
        this.checkYPosition(this.violetBallInfo, this.containerInfo)
      )

      requestAnimationFrame(this.render)
    }
  }
}
</script>
